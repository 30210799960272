<template>
  <div class="vh-100 outer">
    <div class="h-100 m-0 p-0 d-flex align-items-center justify-content-center">
      <div class="auth-container text-center">
        <strong>Please Select Account to Manage:</strong>
        <TeamSelect />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import TeamSelect from "@layouts/Partials/TeamSelect.vue";
export default {
  components: {
    TeamSelect
  },
  data() {
    return {
      user:null,
      business:null
    }
  },
  computed: {
    config() {
      return this.$store.getters['app/config']
    },
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>

</style>
